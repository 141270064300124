import React, { useEffect } from 'react';
import './community.css'; // Make sure to create this CSS file
import 'aos/dist/aos.css';  // AOS styles
import AOS from 'aos';

const Community = () => {

  useEffect(() => {
    AOS.init({
    duration: 1200,  // Animation duration
    once: true,  // Only trigger animations once
    easing: 'ease-in-out',  // Smooth transitions
  });
  }, []);


  return (
    <div className="community-container">

      {/* First Container */}
      <div className="community-intro">
        <h1>Community</h1>
        <h2>Finding Inspiration in Every Turn</h2>
        <p>Welcome to our Community Page. Here, we celebrate the power of<br /> collaboration and the pursuit of inspiration at every opportunity.</p>
      </div>

      {/* Second Container */}
      <div className="community-images">
        <div className="image-row">
          <div className="image-container" >
            <img src="/MayorofNorfolkKennyAlexzander1.png" alt="Image 1" />
            <p>Mayor of Norfolk Kenny Alexzander</p>
            <p>Event: Iron Sharpens Iron</p> {/* Updated line */}
          </div>
          <div className="image-container" >
            <img src="/Olde_Huntersville_Empowerment_Coalition.png" alt="Image 2" />
            <p>Olde Huntersville Empowerment Coalition</p>
            <p>Event: Empowerment Summit</p> {/* Updated line */}
          </div>
          <div className="image-container" >
            <img src="/US_Congressman_Bobby_Scott.png" alt="Image 3" />
            <p>US Congressman Bobby Scott</p>
            <p>Event: Iron Sharpens Iron</p> {/* Updated line */}
          </div>
        </div>
        <div className="image-row" >
          <div className="image-container">
            <img src="/Certificate_of_Appreciation.png" alt="Image 4" />
            <p>Certificate of Appreciation</p>
            <p>Event: Empowerment Summit Collaboration</p> {/* Updated line */}
          </div>
          <div className="image-container" >
            <img src="/MayorofNorfolkKennyAlexzander.png" alt="Image 5" />
            <p>Mayor of Norfolk Kenny Alexzander </p>
            <p>Event: City of Norfolk Holiday Thank You Party</p> {/* Updated line */}
          </div>
          <div className="image-container" >
            <img src="/City_of_Norfolk_Councilman_JP_Paige.png" alt="Image 6" />
            <p>City of Norfolk Councilman JP Paige</p>
            <p>Event: City of Norfolk Holiday Thank You Party</p> {/* Updated line */}
          </div>
        </div>
        <p className="thank-you">Thank you for visiting our Community Page.<br /> Together, let's continue to seek inspiration and create positive change in the world.</p>
      </div>

      {/* Third Container */}
      <div className="community-partners" >
        <h2>Our Partners and Collaborators</h2>
        <p>Take a journey through our past collaborations with various partners and organizations. Each<br /> partnership has been a stepping stone towards our shared goals and vision.</p>
        <div className="logo-container">
          <img src="/OptamusARTS.png" alt="Logo 1" />
          <img src="/StreetzMedia.png" alt="Logo 2" />
          <img src="/NAACP_seal_svg.png" alt="Logo 3" />
        </div>
      </div>
      
    </div>
  );
};

export default Community;
