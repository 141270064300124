import React from 'react';
import './project.css'; // Update CSS file name if needed


const Project = () => {



  return (
    <div className="project-container">
      <h1>Our Products</h1>

      <div className="project-boxes" >
        {/* First Box with photo on the left */}
        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture1.png" alt="Project 1" className="project-img" />
            <div className="text-content">
              <h1>1.Sales Analytics Engine: (DataPulse) </h1>
              <p>
              A system designed to analyse sales data to provide insights into sales performance, trends, and forecasts. It includes a generic data integration platform and an analytics engine. Data can be collected from various sources, and processed and presented through dashboards and reports to help businesses make informed decisions. Most of the processes work automatically except for some controls that are business specific. Can be applied to multiple lines of business.
              </p>
            </div>
          </div>
        </div>

        {/* Second Box with photo on the right */}
        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>2.Metadata Driven ETL Framework: (ETLMaster)</h1>
              <p>
              An ETL (Extract, Transform, Load) framework that uses metadata to control and drive the ETL process. Metadata drift is also captured and automated tweaking is done by the process. Based on the metadata ETL logic and test scripts are automatically created by the framework. Complex business logic is handled by black box transformers which alone need to be edited one time. 
              Tests include various test scenarios that are commonly encountered in typical ETL pipelines. 

              </p>
            </div>
            <img src="/Picture2.png" alt="Project 2" className="project-img" />
          </div>
        </div>

        {/* Third Box with photo on the left */}
        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture3.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>3. Digital Marketing Analytics Platform: (BrandTrackr)</h1>
              <p>
              A platform that aggregates and analyses data from various digital marketing channels (e.g., social media, email, search engines, googledrive, timesheets, JIRA etc) to measure campaign effectiveness, track engagement metrics, and optimize marketing strategies. It also computes affiliate and influencer effectiveness, and revenue generated by them. The main advantage of this system is the myriad of sources it can connect to through API like snapchat, tik-tok , LinkedIn, google drive, timesheet systems, project management tools and many more.
              </p>
            </div>
          </div>
        </div>



        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>4.Errand App: (ErrandEase)</h1>
              <p>
              A mobile or web application designed to help users manage and track their errands. It typically includes features for creating, organizing, and scheduling tasks, reminders, and notifications to ensure timely completion of errands. This is very useful for senior citizens and single parents who can use an extra pair of hands. Servies include shopping, house work , escort to hospitals, deliver packages, or just a friend to talk to. It was received with great feedback and was very useful for many users in dire situations. 
                Can be liaised with other services like food delivery, cab, courier etc for cost effectiveness during product launch.
              </p>
            </div>
            <img src="/Picture4.png" alt="Project 2" className="project-img" />
          </div>
        </div>


        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture5.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>5. Business Solutions Template Based Banking Data Mart: (BankMart Pro)</h1>
              <p>
              A data mart for banking that uses predefined templates to organize and analyze banking data. It provides a structured approach to storing and querying data, enabling efficient reporting and analysis of financial transactions, customer data, and other banking-related information. Will meet the requirements of most banks including nationalized banks , cooperative banks and small rural banks. The data model is predefined based on discussion with multiple customers. Includes modules like CA/SA, Loans, Forex, Credit Cards, Loans , Customer Personalization etc.
              </p>
            </div>
          </div>
        </div>


        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1> 6.Warranty System - Automobile Industry: (DriveSure)</h1>
              <p>
              A system for managing and tracking warranties for vehicles. It includes features for registering warranties, processing claims, and tracking repair history, helping both customers and service providers manage warranty-related issues. The life cycle of each claim, defective part etc are captured. Machine learning and predictive modelling is used to find out fraud by service centres by colluding with customers for faulty claims and sabotage.
              </p>
            </div>
            <img src="/Picture6.png" alt="Project 2" className="project-img" />
          </div>
        </div>

        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture7.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1> 7.Price Optimization System for Multiple Lines of Business: (PricePro)</h1>
              <p>
              A system designed to determine optimal pricing strategies for various products or services. This can be used across different business lines. It uses data analysis and algorithms to adjust prices in response to market conditions, competition, and demand to maximize revenue and profit. Works for retail,  airlines, cinemas, event management and many other lines of business.              </p>
            </div>
          </div>
        </div>

        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1> 8.Pharmacy Management Software: (PharmaSuite)</h1>
              <p>
              A software solution that provides analytics and operative ease for pharmacy. Manages orders, refills, notifications, stock and other metrics for the pharmacy. It also includes features for inventory management, prescription processing, billing, and customer management to streamline pharmacy operations and improve service quality.            
                </p>
            </div>
            <img src="/Picture8.png" alt="Project 2" className="project-img" />
          </div>
        </div>

        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture9.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1> 9. Ticketing System for Issues and Service Requests: (ServiceNet) </h1>
              <p>
              A system used to manage and track issues and service requests submitted by users or customers. It allows for the creation, assignment, and resolution of tickets, providing a structured approach to handling support requests and monitoring performance. It measures SLA breaches, ticket resolution time, KPI for improvement and rewards for resources based on performance.                </p>
            </div>
          </div>
        </div>


        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1> 10. Next Best Action Model for Insurance Prospecting: (Prospect IQ) </h1>
              <p>
              A predictive model that determines the most effective action to take when engaging with insurance prospects. It uses data analysis to recommend the best approach for maximizing conversion rates and improving customer interactions. It typically models based on successful marketers and sales staff and creates a training pattern for other agents.                </p>
            </div>
            <img src="/Picture10.png" alt="Project 2" className="project-img" />
          </div>
        </div>

        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture11.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>  11. Infrastructure and Cybersecurity Monitoring and Automation System: (Threat Trac) </h1>
              <p>
              A system for monitoring and automating the management of IT infrastructure and cybersecurity. It includes tools for tracking system performance, detecting security threats, and automating responses to incidents to ensure the security and reliability of IT environments.              </p>
            </div>
          </div>
        </div>


        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>  12. Forecasting Engine for Amazon Vendor Central: (ForecasterPro) </h1>
              <p>
              A tool for predicting future sales and inventory needs for vendors using Amazon Vendor Central. It leverages historical data and statistical models to generate forecasts, helping vendors optimize inventory levels and plan for demand. Takes into account sales inventory, coupons, promotions, discounts, product hiierarchy and other related data. Uses cloud based data integration system and dashboards.                   </p>
            </div>
            <img src="/Picture12.png" alt="Project 2" className="project-img" />
          </div>
        </div>

        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture13.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>   13. Analytics for Schooling System: (Insight 360) </h1>
              <p>
              A system for analysing educational data within a schooling environment. It includes features for tracking student performance, analysing learning outcomes, and generating reports to support educational decision-making and improve teaching strategies. Includes 12 use cases for overall efficient management of the schooling system. Each use case is supported by a data integration pipeline and an intuitive dashboard.  Can be used for state and federal schooling and college systems.                </p>
            </div>
          </div>
        </div>




        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>   14. Medical Records Compliance Platform: (Med Audit Pro) </h1>
              <p>
              A platform designed to ensure that medical records management adheres to regulatory and compliance standards. It includes features for secure storage, access control, and auditing of medical records to meet legal and industry requirements. Can accommodate global, federal and state government medical standards.
              </p>
                          </div>
            <img src="/Picture14.png" alt="Project 2" className="project-img" />
          </div>
        </div>



        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture15.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>   15. Feedback App for Scholars: (Campus Voice) </h1>
              <p>
              An application for collecting and managing feedback from scholars or students. It allows users to provide and track feedback on various aspects of their academic experience, helping educators and institutions gather insights to improve their programs and services. A very useful product for students, faculty and college administration.
              </p>
                          </div>
          </div>
        </div>

        <h1>COMING 2025 : </h1>


        <div className="project-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>  Streaming App : Spectre  </h1>
              <p>
              Our cutting-edge white-label streaming app offers a customizable and scalable platform tailored to meet the needs of any content provider. Designed to provide a seamless user experience, it supports live and on-demand video content, audio streaming, and personalized recommendations across multiple devices.
Whether you're an entertainment provider, educational institution, or corporate entity, our white-label streaming app gives you the tools to deliver high-quality content to your users, under your brand, with full control over the experience.
              </p>
                          </div>
            <img src="/Picture16.jpg" alt="Project 2" className="project-img" />
          </div>
        </div>






        <div className="project-box box1" >
          <div className="box-content">
            <img src="/Picture17.png" alt="Project 3" className="project-img" />
            <div className="text-content">
              <h1>   Conversational AI platform : ConvoScan   </h1>
              <p>
              Our advanced conversational AI software is designed to deliver seamless, human-like interactions across various channels, transforming customer engagement and automating business processes. Leverages Generative AI and machine learning, this software is capable of understanding, processing, and responding to complex queries with high accuracy.              </p>
                          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
