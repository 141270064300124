import React from 'react';
import './aboutUs.css'; // Import the CSS for styling

const AboutUs = () => {



  return (
    <div className="about-us-container">
      <h1>About Us</h1>

      <div className="about-boxes" >
        {/* First Box with photo on the left */}
        <div className="about-box box1">
          <div className="box-content">
            <img src="/The_Founder.png" alt="The Founder" className="about-img" />
            <div className="text-content">
              <h1>The Founder</h1>
              <p>
                As CEO and Founder of Davidson Technology Solutions, I leverage my over 25 years of contact center operations and technology experience to deliver innovative and effective solutions for improving customer experience and business outcomes. I work with a diverse and talented team of engineers, analysts, and consultants to design, implement, and manage conversational AI, automation, contact center, and digital media projects for global clients across various industries.
              </p>
            </div>
          </div>
        </div>

        {/* Second Box with photo on the right */}
        <div className="about-box box2" >
          <div className="box-content">
            <div className="text-content">
              <h1>My Experience</h1>
              <p>
                My core competencies include OMNIchannel Design, Verint Systems Administration, VOIP, Genesys Call Routing and Cloud, Avaya, and Process Improvement. I have multiple certifications in Verint, Avaya, and Genesys systems, and I am always eager to learn new tools and technologies. I have a proven track record of completing projects on time, within budget, and according to customer expectations. I am passionate about enhancing the quality and efficiency of contact center, military, and business operations, and creating value for both the clients and the company.
              </p>
            </div>
            <img src="/My_Experience.png" alt="My Experience" className="about-img" />
          </div>
        </div>

        {/* Third Box with photo on the left */}
        <div className="about-box box1" >
          <div className="box-content">
            <img src="/Who_Are_We.png" alt="Who Are We" className="about-img" />
            <div className="text-content">
              <h1>Who Are We</h1>
              <p>
                We are a professional group passionate about offering high-quality services to aid, guide, and support data-driven business decisions.
                Our core team has several decades of top IT consulting and Project Management experience in the US and around the world. We strongly believe in offering value-based consulting services.
              </p>
            </div>
          </div>
        </div>

        {/* Fourth Box - Contact */}
        <div className="about-box box2">
  <div className="box-content contact-content" >
    <div className="text-content contact-left">
      <h1>Contact</h1>
      <p>
        I'm always looking for new and exciting opportunities. Let's connect.
      </p>
    </div>
    <div className="contact-right">
      <p>
        <strong>Email:</strong> <a href="mailto:info@mysite.com">Davidsontechnology@outlook.com</a><br />
        <strong>Phone:</strong> 240-361-7568
      </p>
    </div>
  </div>
</div>


      </div>
    </div>
  );
};

export default AboutUs;
