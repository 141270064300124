import React, { useEffect } from 'react';
import './Vision.css'; // Ensure the path to Vision.css is correct



const Vision = () => {



  
  return (
    <div className="vision-page" >
      <header className="vision-container">
        <h1>Our Mission</h1>
        <p>
        At Davidson Technology Solutions, our mission is to offer high-quality services to aid, guide, and support business decisions. We are passionate about what we do and strive to provide our clients with innovative solutions that maximize their potential.
        </p>
      </header>
      
      <section className="vision-story">
        <div   className='Ourstory'>
        <h2  className='storyhead'>Our Story</h2>
        <p className='Ourstorypara'>
        At Davidson Technology Solutions, we are dedicated to helping our clients succeed. Our team of experts has worked with businesses of all sizes, from startups to Fortune 500 companies, and we understand the challenges that come with growing a business in today's fast-paced digital world. Our services are designed to help you make data-driven decisions that will propel your business forward. 
        </p>
        </div>
        
      </section>
      
      <section className="vision-story1">
        <div   className='Ourstory1' >
        <h2  className='storyhead1'>Experienced Leadership</h2>
        <p className='Ourstorypara1'>
        At Davidson Technology Solutions, our team is our greatest asset. We are a group of experienced professionals who are passionate about what we do. Our team has worked on projects around the world, and we bring that experience and expertise to every project we work on. We are committed to helping our clients succeed and we work tirelessly to ensure that every project is a success. Whether you need help with IT consulting, Project Management, or something else entirely, our experienced leadership team is here to help you achieve your goals
        </p>
        </div>
        
      </section>
    </div>
  );
};

export default Vision;
