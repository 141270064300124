import React , { useEffect } from 'react';
import './Footer.css'; // Home-specific styles

const Footer = () => {
       



  return (
    <footer className='footer'>
      <div className='footercontainer'>
        <div className='footersection' >
        <img src="/logo1.png" alt="DTS Logo" 
            className='footerlogo'
          />
          <p className='contactinfo'>240-361-7568</p>
          <p className='contactinfo'>Davidsontechnology@outlook.com</p>
        </div>

        <div className='footersection' >
          <h3 className='footersectiontitle'>Davidson Technology Solutions</h3>
          <ul className='footersectionul'>
            <li className='footersectionli'>Solutions</li>
            <li className='footersectionli'>About Us</li>
            <li className='footersectionli'>Contact Us</li>
          </ul>
          <button className='footerbutton'data-aos="zoom-in">Get Started</button>
        </div>

        <div className='footersection' >
          <h3 className='footersectiontitle'>Stay Up-to-Date with Us</h3>
          <input
            type="email"
            placeholder="Email *"
            className='footerinput'
          />
          <button className='buttonsubscribe'>Subscribe</button>
        </div>

        <div classname='footersction' >
          <h3 className='footersectiontitle'>Follow Us</h3>
          <div className='footericon'>
            <a href="https://www.linkedin.com/in/thomas-davidson-0b818052/" target="_blank" rel="noopener noreferrer">
              <img
                src="/linkdin.png" // Replace with your LinkedIn icon URL
                alt="LinkedIn"
                className='footericon'
              />
            </a>
          </div>
          <p className='footerpara'  >
            © 2021 Davidson Technology Solutions. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
  
export default Footer;
