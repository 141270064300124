import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './header.css'; // Import your custom CSS
import 'aos/dist/aos.css';  // AOS styles
import AOS from 'aos';

const Header = () => {

  useEffect(() => {
    AOS.init({ duration: 1200 });  // Initialize AOS with animation duration
  }, []); 

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  
  
  // Toggle the menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <header className="header">
      <div className="logo1" data-aos="zoom-in">
      <img src="/logo1.png" alt="DTS Logo" />
      </div>
     

      <nav className="navigation">
            {/* Hamburger Menu Icon */}
            <div className="menu-icon" onClick={toggleMenu}>
          <div className={`hamburger-icon ${isOpen ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        {/* Full-screen overlay menu for mobile */}
        <div className={`overlay ${isOpen ? "open" : ""}`}>
          {/* Cross Mark to Close Menu */}
          <div className="close-icon" onClick={toggleMenu}>
            &times;
          </div>

          <ul className="nav-links">
            <li onClick={toggleMenu}><Link to="/">Home</Link></li>
            <li onClick={toggleMenu}><Link to="/about-us">About Us</Link></li>
        
            <li onClick={toggleMenu}><Link to="/community">Community</Link></li>
            <li onClick={toggleMenu}><Link to="/project">Products</Link></li>
            <li onClick={toggleMenu}><Link to="/solutions">solutions</Link></li>
            <li onClick={toggleMenu}><Link to="/vision">Vision</Link></li>
   
           
          </ul>
        </div>
    
                  <Link to="/" className="navLink">Home</Link>
                  <Link to="/about-us" className="navLink">About Us</Link>

        {/* Dropdown Menu */}
        <div className="dropdown" onMouseEnter={handleDropdown} onMouseLeave={handleDropdown}>
        <Link to="/" className="navLink">More</Link>
          {dropdownVisible && (
            <div className="dropdown-menu">
              <Link to="/community" className="dropdown-item">Community</Link>
              <Link to="/solutions" className="dropdown-item">Solutions</Link> {/* Updated path to match casing */}
              <Link to="/project" className="dropdown-item">Products</Link>
              <Link to="/vision" className="dropdown-item">Vision</Link>
            </div>
          )}
        </div>


        <button className="get-started-btn"><Link to="/get-started" className="navLink1">Get Started</Link></button>
      </nav>
    </header>
  );
};

export default Header;
