import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'; // Import global styles
import Header from './Header'; // Adjust based on your file structure
import Footer from './Footer'; // Adjust based on your file structure
import Home from './Home'; // Home page component
import AboutUs from './AboutUs'; // AboutUs page componen
import GetStarted from './GetStarted'; // GetStarted page component
import Solutions from './Solutions'; // Soutions page component
import Vision from './Vision'; // Import Vision component
import Project from './Project'; // Import Project component
import Community from './Community'; // Import Project component


function App() {
  return (
    <div id="page-container">
      <Router>
        <Header />
        <div id="content-wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/get-started" element={<GetStarted/>} />
            <Route path="/solutions" element={<Solutions/>} />
            <Route path="/vision" element={<Vision />} /> {/* Vision Page */}
            <Route path="/project" element={<Project />} /> {/* Vision Page */}
            <Route path="/community" element={<Community />} /> {/* Vision Page */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
