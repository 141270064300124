import React, { useEffect } from 'react';
import './Home.css'; // Home-specific styles




function Home() {
    


  return (
    <div className="home-page"> 
    <div className="home-container">
        <div className="content" >
          <h1 className='head1'>Empowering Your Business</h1>
          <h2 className='head2'>with Technology and Digital Media Support</h2>
          <p className='head3'>Delivering cutting-edge professional services</p>
          <button
              style={{
                  marginTop: '20px',
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  color: 'black',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                  position: 'relative', // Change to relative positioning
                  zIndex: 1 // Ensure it's above other elements if necessary
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(200, 200, 200, 0.8)')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.8)')}
          >
              <a href="/" className="link" style={{ textDecoration: 'none', color: 'inherit' }}>Learn More</a>
          </button>
          
      
        </div>

    </div>
    <div className="content2">

  <div className="leftSection">
    <div className='leftcontainer'>    
      <h1 className="heading1" >
        Let Your Data Take Your Business to the Next Level
      </h1>
      <p className="description1" >
        At Davidson Technology Solutions, we believe that supporting our customers is the key to unlocking your business's true potential. Our team of experts can help empower you to make informed decisions that drive growth and success.
      </p>
    </div>
  </div>


  <div className="rightSection">
    <div className='serviceCard' >
      <span className="icon">🌐</span> Cloud Solutions
      <h2 className="subheading">Cloud Solutions for Modern Businesses</h2>
      <p className="description">
        Our cloud analytics modernization services allow businesses to leverage the power of the cloud to store, analyze, and visualize their data more efficiently and effectively.
      </p>
    </div>

    <div className='serviceCard' >
      <span className="icon">📱</span> Digital Media
      <h2 className="subheading">Customized Applications for Your Unique Needs</h2>
      <p className="description">
        We understand that every business has unique needs. That's why we offer versatile and customizable applications that are tailored to your specific requirements.
      </p>
    </div>

    <div className='serviceCard' >
      <span className="icon">🖥️</span> Custom Applications
      <h2 className="subheading">Accelerating Your Business with Cutting-Edge Digital Media</h2>
      <p className="description">
        Whether a TV commercial, podcast, professional presentation, logos, event graphics, or photography, our team will work closely with you to seamlessly produce all desired content using top-notch, state-of-the-art equipment and technology.
      </p>
    </div>

    <div className='serviceCard'>
      <span className="icon">🤝</span> Customer Experience
      <h2 className="subheading">Providing Exceptional Customer Experience</h2>
      <p className="description">
        At Davidson Technology Solutions, we strive to provide our clients with the highest level of customer service and support.
      </p>
    </div>
  </div>
</div>
<div className='container3home' >
        <div className='innercontainerright'>
          <h2 className='containerh2' >Ready to Transform Your Business?</h2>
          <p className='containerp' >
            We understand that every business has unique needs.
            That's why we offer versatile and customizable applications that are tailored to your specific requirements.
          </p>
          <button className='button1'
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: 'black',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
              alignItems: 'center',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'rgba(200, 200, 200, 0.8)')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.8)')}
          >
            <a href="/" className="link">Get Started</a>
          </button>
        </div>

        <div className='innercontainerleft' >
          <div style={{ position: 'relative', height: '100%', overflow: 'hidden', zIndex: 1 }}>
            <video
              src="/file.mp4" // Ensure the video file is correctly placed in your public folder
              style={{
                width: '100%',
                height: '100%', // Make sure the video takes full height
                display: 'block',
                zIndex: 1,
              }}
              autoPlay
              loop
              muted
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      {/* Partnered with Section */}
      <div className='partnercontainer' >
        <h4 className='Partnered with'>Partnered with:</h4>
      </div>

      <div
        className='partnerhome'
       
      >
      </div>



</div>


  );
}

export default Home;
