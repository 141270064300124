import React, { useEffect, useState } from 'react';
import './getStarted.css';

const GetStarted = () => {
    const [scrolled, setScrolled] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
        jobTitle: '',
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrolled(scrollPosition > 100); // Adjust when scaling occurs
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        // Submit the form data to your PHP URL
        fetch('https://tom.hariombusinessenterprise.com/submit.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData).toString(), // URL-encoded form data
        })
        .then(response => response.text())
        .then(data => {
            console.log('Form submitted successfully:', data);
            alert('Your message has been sent successfully!');
            
            // Reset the form after submission
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                company: '',
                phone: '',
                jobTitle: '',
            });
        })
        .catch(error => {
            console.error('Error submitting form:', error);
            alert('There was an issue sending your message.');
        });
    };

    return (
        <div className="get-started-wrapper">
            {/* Image Container */}
            <div className="image-container">
                <div className={`background-image ${scrolled ? 'scrolled' : ''}`} />
                <div className="text-container1">
                    <div className="get-started-heading">Get Started</div>
                    <div className="get-started-subheading">Let’s give your business what it needs to grow.</div>
                </div>
            </div>
            {/* Information Container */}
            <div className="info-container">
                <div className="text-container">
                    <p>
                        Interested in learning more about how our services can benefit your business?
                        Contact us today to schedule a consultation. Our team of experts is here to
                        answer any questions you may have and help you get started on the path to success.
                    </p>
                </div>
                <div className="divider"></div>
                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    First Name:
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Last Name:
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    Email:
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Company:
                                    <input
                                        type="text"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    Phone Number:
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Job Title:
                                    <input
                                        type="text"
                                        name="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                    />
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="contact-us-btn">Contact Us</button>
                    </form>
                </div>
            </div>
           
        </div>
    );
};

export default GetStarted;
