import React, { useState, useEffect } from 'react'; // Import necessary hooks
import './Solutions.css'; // Ensure the correct path

function Solutions() {
  const [scrolled, setScrolled] = useState(false); // Correct hook

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrolled(scrollPosition > 100); // Adjust this value as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="solutions-container"> {/* Updated class name */}
      <div className="background-image" /> {/* Background image container */}
      <div className={`background-image ${scrolled ? 'scrolled' : ''}`} />
      <h1 className={`welcome-title ${scrolled ? 'scrolled' : ''}`}>
        Welcome to Davidson <br /> Technology Solutions
      </h1>

      <div className="background-box"> {/* Added background box */}
        <div className="second-box">
          <div className="left-box">
            <h2 className="partner-title">Partner with Us for<br /> Success</h2>
          </div>
          <div className="right-box">
            <p className="description">
              At Davidson Technology Solutions, we understand that your business is unique. That's why we work closely with you to develop customized solutions that meet your specific needs. Below is a list of services that we provide with top-notch expertise:
            </p>
            <ul className="services-list">
              <li>Data Migration</li>
              <li>ETL Reporting</li>
              <li>Manual & Automated Testing</li>
              <li>BIG DATA</li>
              <li>Cloud (AWS, Azure, Google)</li>
              <li>Data Quality</li>
              <li>Legacy Applications Maintenance, Upgrades & Conversion</li>
              <li>Data Analytics</li>
              <li>Website/App Development</li>
              <li>Hadoop and Spark</li>
              <li>Machine Learning</li>
              <li>Deep Learning</li>
              <li>Artificial Intelligence</li>
              <li>Text Mining and NLP</li>
              <li>AVAYA</li>
              <li>Verint</li>
              <li>NICE</li>
              <li>IOT</li>
              <li>Design Thinking</li>
              <li>Software Testing and Robotic Process Automation</li>
              <li>Project Management</li>
              <li>DevOps</li>
              <li>Process Audits (ISO)</li>
              <li>Full Digital Media Services</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
